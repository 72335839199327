import React from 'react';

import classNames from 'classnames';
import Link from 'next/link';

import Image from '@/app/_components/image';
import ArrowIcon from '@/public/icons/chevron-right.svg';
import { NormalizedMenuGroup } from '@/types/menus';

import LinkItem from './link-item';

import classes from './main-nav.module.scss';

interface ImageLinkProps {
  href?: string;
  imgUrl: string;
  imgTitle?: string;
}

const ImageLink: React.FC<ImageLinkProps> = (props) => {
  const { href, imgUrl, imgTitle } = props;

  const img = (
    <div className={classes.imgWrapper}>
      <Image
        src={imgUrl}
        alt={imgTitle ? `${imgTitle} menu item` : 'Menu item'}
        width={708}
        height={500}
        className={classes.img}
      />
    </div>
  );

  if (!href) return img;

  return (
    <Link href={href} prefetch={false} aria-label={imgTitle}>
      {img}
    </Link>
  );
};

type Props = {
  name: string | JSX.Element;
  open: boolean;
  onClose: () => void;
  itemsList: NormalizedMenuGroup[];
  imgUrl?: string;
  imgTitle?: string;
  imgDescription?: string;
  imgDestination?: string;
};

const SubMenu: React.FC<Props> = (props) => {
  const {
    name,
    open,
    onClose,
    itemsList,
    imgUrl,
    imgTitle,
    imgDescription,
    imgDestination,
  } = props;

  return (
    <div
      className={classNames(classes.subMenu, {
        [classes.activeSubMenu]: open,
      })}
    >
      <div className={classes.contentWrapper}>
        <div className={classes.imgBlock}>
          {imgUrl ? (
            <ImageLink
              href={imgDestination}
              imgUrl={imgUrl}
              imgTitle={imgTitle}
            />
          ) : null}
          <div className={classes.imgTitle}>{imgTitle}</div>
          <div className={classes.imgDescription}>{imgDescription}</div>
        </div>
        <div className={classes.subMenuItemsWrapper}>
          <button type="button" className={classes.btnBack} onClick={onClose}>
            {name}
            <ArrowIcon className={classes.arrow} />
          </button>
          {itemsList.map((sItem) => (
            <div key={sItem.title}>
              {sItem.children.length ? (
                <div>
                  <div className={classes.categoriesTitle}>{sItem.title}</div>
                  <div>
                    {sItem.children.map((el) => (
                      <div key={el.id} className={classes.linksWrapper}>
                        {el.imageUrl ? (
                          <div className={classes.linkImage}>
                            <Image
                              src={el.imageUrl}
                              width={32}
                              height={32}
                              alt={
                                el.name && name
                                  ? `${el.name} ${name.toString().toLowerCase()} menu item`
                                  : 'Menu item'
                              }
                            />
                          </div>
                        ) : null}
                        <div>
                          <LinkItem
                            name={el.name}
                            href={el.href || ''}
                            hasGroups={false}
                            classNames={classes.link}
                            onClick={el.onClick}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubMenu;
